import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  Button,
  Image,
  Grid,
  Tooltip,
} from '@chakra-ui/react';
import { FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Integrations = () => {
  const [isSalesforceConnected, setIsSalesforceConnected] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [isRedtailConnected, setIsRedtailConnected] = useState(false);
  const [isZoomConnected, setIsZoomConnected] = useState(false);
  const [isMicrosoftConnected, setIsMicrosoftConnected] = useState(false);

  useEffect(() => {
    getConnections();
  }, []);

  useEffect(() => {
    console.log('isSalesforceConnected', isSalesforceConnected);
  }, [isSalesforceConnected]);

  useEffect(() => {
    console.log('isZoomConnected', isZoomConnected);
  }, [isZoomConnected]);

  function getConnections() {
    const token = Cookies.get('jwtToken');
    // console.log("JWT Token:", token); // Add this to check the token in the console

    axios.get(`${serverUrl}/api/organization-connections`, {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true
    })
    .then(res => {
        console.log(res);
        const salesforceConnected = res.data.some(crm => crm.name.toLowerCase() === 'salesforce');
        setIsSalesforceConnected(salesforceConnected);
        setIsRedtailConnected(res.data.some(crm => crm.name.toLowerCase() === 'redtail'));
    })
    .catch(err => {
        console.log("Error fetching connections", err);
        setIsSalesforceConnected(false);
    });

    axios.get(`${serverUrl}/api/user-connections`, {
      headers: { Authorization: `Bearer ${token}` },
      withCredentials: true
    })
    .then(res => {
        // console.log('User Connections:', res);
        const zoomConnected = res.data.some(connection => connection.name.toLowerCase() === 'zoom');
        setIsZoomConnected(zoomConnected);
        const microsoftConnected = res.data.some(connection => connection.name.toLowerCase() === 'microsoft');
        setIsMicrosoftConnected(microsoftConnected);
    })
    .catch(err => {
        console.log("Error fetching user connections", err);
        setIsZoomConnected(false);
    });
}

  function connectToSalesforce() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/salesforce/auth?token=${token}`;
  }

  function connectToRedTail() {
    navigate('/redtail/connect');
  }

  function connectToWealthbox() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/wealthbox/auth?token=${token}`;
  }

  function connectToOutlook() {
    window.location.href = `${serverUrl}/outlook/connect`;
  }

  function connectToZoom() {
    const token = Cookies.get('jwtToken');
    window.location.href = `${serverUrl}/auth/zoom?token=${token}`
  }
  return (
    <Box bg="white" p={8}>
      <Heading color={'#00417D'} fontSize={'32px'}>Integrations</Heading>
      
      {/* Organization Level Integrations Subheader */}
      <Box display="flex" alignItems="center">
        <Heading color={'#00417D'} fontSize={'24px'} mt={8}>Organization Level Integrations</Heading>
        <Tooltip 
          label="Connecting these integrations will grant DataDasher access for all users within your organization, provided they have registered a DataDasher account. For example, if your email is john@financialadvisory.com and you are part of the Financial Advisory organization, then jane@financialadvisory.com, also in the same organization, will have access to the integration and be able to view clients within the integrated CRM." 
          aria-label="Organization Level Integrations Info" 
          maxWidth="500px" // Set the desired width here
        >          
          <span style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '15px', verticalAlign: 'middle' }}>
            <FaInfoCircle />
          </span>
        </Tooltip>
      </Box>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} mt={4}>
        <ActionBox image={<Image src="/images/Redtail.png"/>} description="Redtail" description2={<Text mt={1}>Automatically update CRM fields and tasks in Redtail from your email and calls.</Text>} buttonText={isRedtailConnected ? "Connected" : "Connect"} buttonAction={connectToRedTail} />
        <ActionBox image={<Image src="/images/Wealthbox.png"/>} description="Wealthbox" description2={<Text mt={1}>Automatically update CRM fields and tasks in Wealthbox from your email and calls.</Text>} buttonText="Connect" buttonAction={connectToWealthbox} />
        <ActionBox image={<Image src="/images/Salesforce.png"/>} description="Salesforce" description2={<Text mt={1}>Automatically update CRM fields and tasks in Salesforce from your email and calls.</Text>} buttonText={isSalesforceConnected ? "Connected" : "Connect"} buttonAction={connectToSalesforce} />
        <ActionBox image={<Image src="/images/Dropbox.png"/>} description="Dropbox" description2={<Text mt={1}>Use files from Dropbox as an information source for your clients.</Text>} buttonText="Connect" buttonAction={() => { }} />
      </Grid>

      {/* User Level Integrations Subheader */}
      <Box display="flex" alignItems="center">
        <Heading color={'#00417D'} fontSize={'24px'} mt={8}>User Level Integrations</Heading>
        <Tooltip 
          label="User Level Integrations allow individual users to connect their accounts to DataDasher. This means that each user can access and manage their own integrations independently, ensuring that their data remains private and secure. For example, if you connect your individual email or calendar, only you will have access to that information within DataDasher." 
          aria-label="User Level Integrations Info" 
          maxWidth="500px" // Set the desired width here
        >          
          <span style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '15px', verticalAlign: 'middle' }}>
            <FaInfoCircle />
          </span>
        </Tooltip>
      </Box>
      <Grid templateColumns="repeat(3, 1fr)" gap={6} mt={4}>
        <ActionBox image={<Image src="/images/Outlook_integration.png"/>} description="Outlook" description2={<Text mt={1}>Use emails from Outlook as an information source for your clients.</Text>} buttonText={isMicrosoftConnected ? "Connected" : "Connect"} buttonAction={connectToOutlook} />
        <ActionBox image={<Image src="/images/Zoom.png"/>} description="Zoom" description2={<Text mt={1}>Automatically transcribe audio, take notes, and generate action items from Zoom.</Text>} buttonText={isZoomConnected ? "Connected" : "Connect"} buttonAction={connectToZoom} />
        <ActionBox image={<Image src="/images/Google_Meet.png"/>} description="Google Meet" description2={<Text mt={1}>Automatically transcribe audio, take notes, and generate action items from Google Meet.</Text>} buttonText="Connect" buttonAction={() => { }} />
      </Grid>
    </Box>
  );
};

const ActionBox = ({ image, description, description2, buttonText, buttonAction }) => {
  return (
    <Box bg="#F5F5F5" pt={8} pl={8} pr={8} w="100%" h={'350px'} position={'relative'} borderRadius={'8px'}>
      <VStack textAlign={'center'}>
        {image}
        <Text mt={4} fontSize={'18px'}>{description}</Text>
        {description2}
        <Button onClick={buttonAction} bg={'#A3BBD0'} w={'150px'} h={'50px'} position={'absolute'} bottom={'30px'}>
          <Text fontWeight={400}>{buttonText}</Text>
        </Button>
      </VStack>
    </Box>
  );
};

export default Integrations;