import React, { useState, useEffect } from 'react';
import { Box, Collapse, IconButton, Text, Textarea, useDisclosure, Image, HStack, VStack } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaUser, FaSave, FaEdit, FaInfo } from 'react-icons/fa';
import BlueBox from './BlueBox';
import axios from 'axios';
import { PrismaClient } from '@prisma/client';
const prisma = new PrismaClient();

const GrayBox = ({ imageSrc, title, subtitle, listItems, rows, transcriptId, client, id, type, scrollRef , onArchive, errorItems, correspondence }) => {
  // console.log('Image Src:', imageSrc);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);
  const [stateImageSrc, setStateImageSrc] = useState(imageSrc);
  const [stateTitle, setStateTitle] = useState(title);
  const [stateSubtitle, setStateSubtitle] = useState(subtitle);
  const [stateItems, setStateItems] = useState(listItems);
  const [stateRows, setStateRows] = useState(rows);
  const [stateTranscriptId, setStateTranscriptId] = useState(transcriptId);
  const [stateClient, setStateClient] = useState(client);
  const [stateId, setStateId] = useState(id);
  const [stateType, setStateType] = useState(type);
  const [stateCorrespondence, setStateCorrespondence] = useState(correspondence);
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setStateImageSrc(imageSrc);
    setStateTitle(title);
    setStateSubtitle(subtitle);
    setStateItems(listItems.map(item => item.split('\n')));
    setStateRows(rows);
    setStateCorrespondence(correspondence);
  }, [imageSrc, title, subtitle, listItems, rows, correspondence]);

  useEffect(() => {
    setStateTranscriptId(transcriptId); 
    setStateClient(client);
    setStateId(id);
    setStateType(type);
  }, [transcriptId, client, id, type]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (index, value) => {
    const newItems = [...stateItems];
    newItems[index] = value;
    setStateItems(newItems);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    try {
      const summary = stateItems.join('. '); // Join items with period and space
      console.log('summary in handleSaveClick: ', summary);
  
      await axios.put(`${serverUrl}/update-summary/${stateId}`, { summary });
  
      console.log('Summary updated successfully');
    } catch (error) {
      console.error('Error updating summary:', error);
    }
  };

  const handleMouseEnter = () => {
    setShowErrorTooltip(true);
    errorItems.forEach(item => console.log(item));
  };

  const handleMouseLeave = () => {
    setShowErrorTooltip(false);
  };

  return (
    <Box bg="#F5F5F5" p={4} mt={4} borderRadius={20} position="relative">
      <HStack alignItems="flex-start" spacing={4}>
        {imageSrc ? (
          <Image 
            src={stateImageSrc}
            alt={stateTitle} 
            boxSize="50px" 
            objectFit="cover" 
          />
        ) : (
          <Box 
            height="50px"
            width="50px"
            bg="#00417D" 
            borderRadius="full" 
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <FaUser color="white" />
          </Box>
        )}
        <VStack align="flex-start" spacing={0}>
          <Text fontWeight="bold">
            {stateTitle}
          </Text>
          <Text>
            {stateSubtitle}
          </Text>
        </VStack>
      </HStack>
      <HStack mt={2} ml={'60px'} w="calc(100% - 60px - 50px)" alignItems="flex-start" justifyContent="space-between">
        <VStack align="flex-start" spacing={2} flex="1">
            {stateItems.map((item, index) => (
              <HStack key={index} width="100%" alignItems="flex-start">
                {isEditing ? (
                  <Textarea 
                    value={item}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    flex="1"
                    resize="vertical"
                  />
                ) : (
                  <Text flex="1" whiteSpace="normal">{item}</Text>
                )}
              </HStack>
            ))}
        </VStack>
        {stateItems.length > 0 && (
          <IconButton
            size="sm"
            aria-label={isEditing ? "Save" : "Edit"}
            icon={isEditing ? <FaSave /> : <FaEdit />}
            variant="ghost"
            ml={2}
            onClick={isEditing ? handleSaveClick : handleEditClick}
            alignSelf="center"
          />
        )}
      </HStack>
      <HStack position="absolute" top={2} right={2} spacing={2}>
        {errorItems.length > 0 && (
          <Box position="relative">
            <IconButton
              aria-label="Error Info"
              icon={<FaInfo color="red" />}
              variant="ghost"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              top = {2}
              right = {12}
            />
            {showErrorTooltip && (
              <Box
                position="absolute"
                bg="gray.200"
                p={2}
                borderRadius="md"
                boxShadow="md"
                zIndex={1}
                top="100%"
                left="-450px"
                width="500px"
              >
                {errorItems.map((data, index) => (
                  <Box key={index} mt={5}>
                  <Text color="red.500">
                    Action: {data.description}
                  </Text>
                  <Text color="red.500">
                    Error: {data.aiCompletionMessage}
                  </Text>
                </Box>
                ))}
              </Box>
            )}
          </Box>
        )}
        <IconButton
          aria-label="Expand"
          icon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          onClick={onToggle}
          variant="ghost"
          position="absolute"
          top={2}
          right={2}
        />
      </HStack>
      <Collapse in={isOpen} animateOpacity>
        <BlueBox 
          actions={stateRows} 
          transcriptId={stateTranscriptId} 
          client={stateClient} 
          id={stateId} 
          type={stateType} 
          onArchive={(itemId) => {
            onArchive(itemId);
            onClose();
          }} 
          scrollRef={scrollRef}
          correspondence={stateCorrespondence}
        />
      </Collapse>
    </Box>
  );
};

export default GrayBox;