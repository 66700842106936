import React, { useEffect, useState } from 'react';
import { Box, Heading, Text, VStack, HStack, Flex, IconButton, Progress, Button, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { ArrowBackIcon, ChevronLeftIcon, ChevronRightIcon, RepeatIcon, SearchIcon } from '@chakra-ui/icons';
import { FaPlay, FaPause } from 'react-icons/fa';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

const Correspondence = () => {
  const { id } = useParams();
  const [callData, setCallData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const serverUrl = process.env.REACT_APP_API_URL;
  const audioRef = React.useRef(null);

  useEffect(() => {
    const fetchTranscript = async () => {
      try {
        const response = await axios.get(serverUrl + `/transcripts/${id}`, { headers: { Authorization: `Bearer ${Cookies.get('jwtToken')}` }, withCredentials: true });
        console.log("Received Response: ", response.data);
        setCallData(response.data);
      } catch (error) {
        console.error("Error fetching transcript:", error);
      }
    };
    fetchTranscript();
  }, [id, serverUrl]);

  const handlePlayPause = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = () => {
    const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
    setProgress(progress);
  };

  const handleSeek = (e) => {
    const seekTime = (e.target.value / 100) * audioRef.current.duration;
    audioRef.current.currentTime = seekTime;
    setProgress(e.target.value);
  };

  const handleSkip = (seconds) => {
    audioRef.current.currentTime += seconds;
  };

  const jumpToTimestamp = (timestamp) => {
    audioRef.current.currentTime = timestamp;
    if (!isPlaying) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const filteredTranscription = callData?.call.transcription.filter(entry =>
    entry.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
    entry.speaker_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (!callData) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box bg="white" h="100%" p={8} pb={24} overflowY="auto">
      <IconButton
        aria-label="Go back"
        icon={<ArrowBackIcon />}
        onClick={() => window.history.back()}
        mb={4}
      />
      <VStack align="stretch" spacing={4}>
        <Heading color={'#00417D'} fontSize={'32px'}>{callData.call.title}</Heading>
        
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            placeholder="Search transcript"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        
        <Box>
          {filteredTranscription.map((entry, index) => ( 
            <Flex key={index} mt={2} align="start">
              <Box>
                <Text as="span" fontWeight="bold">{entry.speaker_name}: </Text>
                <Text as="span">{entry.text}</Text>
              </Box>
              <Button 
                size="xs" 
                ml={2} 
                mt={1}
                colorScheme="blue"
                variant="link"
                onClick={() => jumpToTimestamp(entry.start_timestamp)}
              >
                {formatTime(entry.start_timestamp)}
              </Button>
            </Flex>
          ))}
        </Box>
      </VStack>

      {/* Audio Player */}
      <Box
        position="fixed"
        bottom={0}
        left={256}
        right={8}
        bg="gray.800"
        color="white"
        p={4}
        zIndex={1000}
      >
        <Flex align="center" justify="space-between">
          <IconButton
            icon={isPlaying ? <FaPause /> : <FaPlay />}
            onClick={handlePlayPause}
            aria-label={isPlaying ? "Pause" : "Play"}
            colorScheme="blue"
            size="lg"
          />
          <HStack spacing={4} flex={1} mx={4}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => handleSkip(-10)}
              aria-label="Rewind 10 seconds"
            />
            <Progress
              value={progress}
              flex={1}
              size="sm"
              colorScheme="blue"
              onChange={handleSeek}
            />
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => handleSkip(10)}
              aria-label="Forward 10 seconds"
            />
          </HStack>
          <IconButton
            icon={<RepeatIcon />}
            onClick={() => {
              audioRef.current.currentTime = 0;
              audioRef.current.play();
            }}
            aria-label="Restart"
          />
        </Flex>
        <audio
          ref={audioRef}
          src={callData.audioUrl}
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => setIsPlaying(false)}
          style={{ display: 'none' }}
        />
      </Box>
    </Box>
  );
};

export default Correspondence;