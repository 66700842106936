import React, { useEffect, useState } from 'react';
import { Box, Heading, Switch, HStack, Text, Spinner } from '@chakra-ui/react';
import GrayBox from '../../GrayBox';
import axios from 'axios';
import Cookies from 'js-cookie';

const Home = ({scrollRef}) => {
    const serverUrl = process.env.REACT_APP_API_URL;
    const [myFeed, setMyFeed] = useState([]);
    const [loading, setLoading] = useState(true);
    const [viewingTranscript, setViewingTranscript] = useState(false);

    useEffect(() => {
      setLoading(true);
      axios.get(`${serverUrl}/api/my-feed`, {
        headers: {
          'Authorization': `Bearer ${Cookies.get('jwtToken')}`
        }
      })
        .then(response => {
          console.log('My Feed:', response.data);
          return response.data;
        })
        .then(data => {
          console.log('Action Items:', data);
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
            const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
            return dateB - dateA; // Descending order
          });
          setMyFeed([...sortedData]);
          setLoading(false);

        });
    }, []);


  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Correspondences:', showArchived);
    setLoading(true);
    axios.get(`${serverUrl}/api/my-feed?showArchived=${showArchived}`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        console.log('Archived Correspondences:', response.data);
        return response.data;
      })
      .then(data => {
        console.log('Archived Correspondences:', data);
        const sortedData = data.sort((a, b) => {
          const dateA = new Date(a.metadata?.sentDateTime || a.receivedAt || a.startTime);
          const dateB = new Date(b.metadata?.sentDateTime || b.receivedAt || b.startTime);
          return dateB - dateA; // Descending order
        });
        setMyFeed([...sortedData]);
        setLoading(false);
      });
  }

  useEffect(() => {
    const handleScrollPosition = () => {
      const savedPosition = sessionStorage.getItem('scrollPosition');
      if (savedPosition && scrollRef.current) {
        scrollRef.current.scrollTo(0, parseInt(savedPosition, 10));
        sessionStorage.removeItem('scrollPosition'); 
      }
    };
  
    if (!loading) {
      handleScrollPosition();
    }
  }, [loading, scrollRef]); // Include scrollRef in the dependency array

  const handleArchive = (itemId) => {
      console.log('Archive', itemId);
      // Update the state to remove the archived item
      let newFeed = myFeed.filter(item => item.id !== itemId);
      setMyFeed([...newFeed] );
  }

    // console.log('GrayBox Data:', grayBoxData); // Debugging that GrayBox is being passed correctly.
    return (
        <Box bg="white" p={8} overflowY="auto">
          <Heading color={'#00417D'} pb={4}>My Feed</Heading>
          <HStack justify="flex-end" mb={4}>
            <Text>View Archived Correspondences</Text>
            <Switch
              onChange={(e) => {
                const showArchived = e.target.checked;
                handleShowArchived(showArchived);
                // Logic to handle the switch state change
                console.log('Show Archived Correspondences:', showArchived);
                // You can add more logic here to filter correspondences based on the switch state
              }}
            />
          </HStack>
          {loading && <Spinner />}
          {!loading && myFeed.map((data, index) => (  
            <GrayBox
              key={index}
              imageSrc={data?.imageSrc}
              title={
                data.correspondenceType === 'email' ? 
                `${data?.clients.map(client => `${client.firstName} ${client.lastName}`).join(', ')} ${new Date(data?.metadata?.sentDateTime || data?.receivedAt).toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}` :
                data.correspondenceType === 'call' ? 
                `${data?.clients.map(client => `${client.firstName} ${client.lastName}`).join(', ')} ${data?.event ? data.event.subject : ''} ${data?.event ? new Date(data.event.startTime).toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) : new Date(data.startTime).toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}` :
                `${data?.clients.map(client => `${client.firstName} ${client.lastName}`).join(', ')} ${new Date(data?.startTime).toLocaleString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}`
              }
              subtitle={data?.correspondenceType.charAt(0).toUpperCase() + data?.correspondenceType.slice(1)}
              listItems={data?.correspondenceType === 'email' 
                ? data?.metadata?.summary?.split('.').map(item => item.trim()).filter(item => item) || []
                : data?.correspondenceType === 'call' ? // Check for 'call'
                data?.summary?.split('.').map(item => item.trim()).filter(item => item) || [] :
                [] // Default case if neither 'email' nor 'call'
              }
              rows={data?.ActionItem} 
              transcriptId={data?.id}
              client={data?.clients}
              type={data?.correspondenceType}
              id={data?.id}
              onArchive={() => handleArchive(data.id)} // Pass the archive handler
              scrollRef={scrollRef}
              errorItems={data?.ActionItem.filter(data => data.aiCompletionStatus === 'ERROR')} 
              correspondence={data}
            />
          ))}
        {(myFeed.length === 0 && !loading) && (
            <Text>No items to display</Text>
        )}
        </Box>
      );
    };
    
    export default Home;