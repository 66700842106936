import React from 'react';
import { Box, HStack, VStack, Switch } from '@chakra-ui/react';
import RowItem from './RowItem';
import ActionButton from './ActionButton';
import { FiCheckCircle, FiUser, FiExternalLink } from 'react-icons/fi';
import { IconButton, Text, Button, Icon, Tooltip, useToast } from '@chakra-ui/react';
import { CheckIcon, CloseIcon, CheckCircleIcon, ExternalLinkIcon, ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import { IoMdHappy, IoMdSad } from 'react-icons/io';
import { FaRegUser } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FeedbackModal from './FeedbackModal';
import axios from 'axios';
import Cookies from 'js-cookie';

const BlueBox = ({ actions, transcriptId, client, id, type, scrollRef, onArchive, correspondence }) => {
  const sfUrl = 'https://amazing-einstein-73385-dev-ed.develop.lightning.force.com/lightning/r/'
  const toast = useToast();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null);
  const serverUrl = process.env.REACT_APP_API_URL;
  const [actionItems, setActionItems] = useState(actions);
  const [showArchived, setShowArchived] = useState(false);
  const navigate = useNavigate();
  const [stateTranscriptId, setStateTranscriptId] = useState(transcriptId);
  const [stateClient, setStateClient] = useState(client);
  const [stateId, setStateId] = useState(id);
  const [stateType, setStateType] = useState(type);
  const [stateCorrespondence, setStateCorrespondence] = useState(correspondence);
  useEffect(() => {
    setStateTranscriptId(transcriptId);
    setStateClient(client);
    setStateId(id);
    setStateType(type);
    setStateCorrespondence(correspondence);
  }, [transcriptId, client, id, type, onArchive]);
  useEffect(() => {
    // console.log(actions);
    setActionItems(actions);
  }, [actions]);

  const handleApprove = (item) => {
    console.log('Approve');
    // post this to the server /api/actionItems/:id/approve
    axios.post(`${serverUrl}/api/actionItems/${item.id}/approve`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleViewTranscript = async () => {
    if (scrollRef && scrollRef.current) {
      sessionStorage.setItem('scrollPosition', scrollRef.current.scrollTop.toString());
      console.log('Saving scroll position:', scrollRef.current.scrollTop);
    }
    navigate(`/transcripts/${transcriptId}`);
  };

  const handleViewEmail = () => {
    if (scrollRef && scrollRef.current) {
      sessionStorage.setItem('scrollPosition', scrollRef.current.scrollTop.toString());
      console.log('Saving scroll position:', scrollRef.current.scrollTop);
    }

    axios.get(`${serverUrl}/api/emails/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      }
    })
      .then(response => {
        return response.data;
      })
      .then(emailData => {
        console.log('Email Data:', emailData);
        // Check if the webLink is available in the email data
        if (emailData && emailData.metadata.webLink) {
          console.log('Navigating to email web link:', emailData.metadata.webLink);
          window.open(emailData.metadata.webLink, '_blank'); // Open the email link in a new tab
        } else {
          console.error('No web link available for this email');
        }
      })
      .catch(error => {
        console.error('Error fetching email:', error);
      });
  };

  const handleArchive = (item) => {
    console.log('Archive');
    axios.post(`${serverUrl}/api/action-items/${item.id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchive = (item) => {
    console.log('Unarchive');
    axios.post(`${serverUrl}/api/action-items/${item.id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleArchiveCall = (id) => {
    console.log('Archive Call');
    axios.post(`${serverUrl}/api/calls/${id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchiveCall = (id) => {
    console.log('Unarchive Call');
    axios.post(`${serverUrl}/api/calls/${id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleArchiveEmail = (id) => {
    console.log('Archive Email');
    axios.post(`${serverUrl}/api/emails/${id}/archive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleUnarchiveEmail = (id) => {
    console.log('Unarchive Email');
    axios.post(`${serverUrl}/api/emails/${id}/unarchive`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
    });
  }

  const handleShowArchived = (showArchived) => {
    console.log('Show Archived Actions:', showArchived);
    if (type === 'call') {
      axios.get(`${serverUrl}/api/calls/${id}/action-items?showArchived=${showArchived}`, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(res => {
        console.log(res);
        setActionItems(res.data);
      });
    } else if (type === 'email') {
      axios.get(`${serverUrl}/api/emails/${id}/action-items?showArchived=${showArchived}`, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(res => {
        console.log(res);
        setActionItems(res.data);
      });
    }
  }
  const handleFeedback = (feedback) => {
    console.log('Feedback:', feedback);
    let data = {
      details: feedback,
    }

    axios.post(`${serverUrl}/api/feedback/${feedbackId}`, data, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('jwtToken')}`
      },
    }).then(res => {
      console.log(res);
      setShowFeedbackModal(false);
    });
    toast({
      title: "Feedback Submitted",
      description: `Thank you for your feedback!`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  }
  return (
    <>
      <Box mt={2} bg={'#00417D33'} borderRadius={'8px'} p={5}>
        <HStack w={'100%'} h={'100%'} alignItems="flex-start">
          <VStack w={'75%'} align={'flex-start'} h={'100%'}>
            {actionItems?.length === 0 && (
              <Text>No action items found</Text>
            )}
            {actionItems?.map((item, index) => (
              <HStack key={index} w={'100%'} justify={'space-between'}>
                <Box>
                  <HStack>
                    {(item.aiAction && !showArchived) && (
                      <>
                        {item.aiAction && item.aiCompletionStatus === 'PENDING' && (
                          <IconButton aria-label="Execute Action Item" icon={<CheckIcon />} bg={'none'} color={'green'} alignSelf={'flex-start'}
                            onClick={() => {
                              const data = {
                                details: '',
                                sentiment: 'POSITIVE',
                                actionItemId: item.id,
                              };
                              axios.post(`${serverUrl}/api/feedback`, data, {
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                                },
                              })
                              toast({
                                title: "Executing Action Item",
                                description: `Executing ${item.description}...`,
                                status: "info",
                                duration: 9000,
                                isClosable: true,
                              })
                              handleApprove(item)
                              // set the aiCompletionStatus to SUCCESS
                              setActionItems(actionItems.map(i => i.id === item.id ? { ...i, aiCompletionStatus: 'SUCCESS' } : i));
                            }}
                          ></IconButton>
                        )}
                        {item.aiAction && item.aiCompletionStatus === 'PENDING' && (
                          <IconButton aria-label="Remove Action Item" icon={<CloseIcon />} bg={'none'} color={'red'} alignSelf={'flex-start'}
                            onClick={() => {
                              const data = {
                                details: '',
                                sentiment: 'NEGATIVE',
                                actionItemId: item.id,
                              };

                              axios.post(`${serverUrl}/api/feedback`, data, {
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                                },
                              }).then(res => {
                                console.log(res);
                                setFeedbackId(res.data.id);
                                toast({
                                  title: "Action Item Closed",
                                  description: (
                                    <>
                                      Give us feedback on why you closed it by clicking here so we can be more helpful for next time!
                                      <Button variant="link" onClick={() => setShowFeedbackModal(true)} cursor={'pointer'}>
                                        <Text color={'white'}>Click here to submit feedback</Text>
                                      </Button>
                                    </>
                                  ),
                                  status: "warning",
                                  duration: 5000,
                                  isClosable: true,
                                })
                                // archive and remove from the list
                                handleArchive(item);
                                setActionItems(actionItems.filter(i => i.id !== item.id));
                              }).catch(error => {
                                console.error("Error submitting feedback:", error);
                                toast({
                                  title: "Error",
                                  description: `There was an error submitting your feedback: ${error.message}`,
                                  status: "error",
                                  duration: 5000,
                                  isClosable: true,
                                });
                              });
                            }}
                          ></IconButton>
                        )}
                      </>
                    )}
                    <Box w={'100%'}>
                      <Text
                        as={item.aiCompletionStatus === 'SUCCESS' ? 's' : 'span'}
                        color={item.aiCompletionStatus === 'SUCCESS' ? 'gray' : 'black'}
                      >
                        {item.description}
                      </Text></Box>
                  </HStack>
                </Box>
                <Tooltip label={showArchived ? "Unarchive" : "Archive"} >
                  <Button size="sm" onClick={() => {
                    toast({
                      title: "Archiving Action Item",
                      description: `Archiving ${item.description}...`,
                      status: "info",
                      duration: 1000,
                      isClosable: true,
                    })
                    if (showArchived) {
                      handleUnarchive(item);
                    } else {
                      handleArchive(item);
                    }
                    setActionItems(actionItems.filter(i => i.id !== item.id));
                  }} alignSelf={'flex-start'}>
                    {showArchived ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </Tooltip>
              </HStack>
            ))}
          </VStack>
          <VStack w={'25%'} alignContent={'flex-start'} justify={'flex-start'} h={'100%'}>
            <HStack alignItems="flex-start" mt={2}>
              <Text>View Archived Actions</Text>
              <Switch
                onChange={(e) => {
                  const showArchived = e.target.checked;
                  handleShowArchived(showArchived);
                  setShowArchived(showArchived);
                  // Logic to handle the switch state change
                  console.log('Show Archived Actions:', showArchived);
                  // You can add more logic here to filter actions based on the switch state
                }}
              />
            </HStack>
          </VStack>
        </HStack>
        <HStack mt={4} justify={'space-between'}>
          <HStack gap={1}>
            {actionItems?.some(item => item.aiAction && item.aiCompletionStatus === 'PENDING') && (
              <Button
                onClick={() => toast({
                  title: "Approving All Action Items",
                  description: `Now executing all action items for ${client.name}...`,
                  status: "info",
                  duration: 1000,
                  isClosable: true,
                })}
              >
                <CheckCircleIcon mr={2} color={'green'} />
                Approve All
              </Button>
            )}
            <Button onClick={type === 'email' ? handleViewEmail : handleViewTranscript}>
              <Icon as={FaRegUser} mr={2} />
              {type === 'email' ? 'View Email' : 'View Transcript'}
            </Button>
            <Button>
              <ExternalLinkIcon mr={2} />
              Redtail</Button>
            <Button>
              <ExternalLinkIcon mr={2} />
              Orion</Button>
            <Button>
              <ExternalLinkIcon mr={2} />
              Dropbox</Button>
            <Button onClick={() => window.open(`${sfUrl}${client[0]?.metadata?.Id}/view`, '_blank')}>
              <ExternalLinkIcon mr={2} />
              Salesforce</Button>
            {(
              <Button onClick={() => {
                toast({
                  title: "Archiving",
                  description: `Archiving ${stateClient?.name || 'user'}'s correspondence...`,
                  status: "info",
                  duration: 1000,
                  isClosable: true,
                })

                if (stateType === 'call' && stateCorrespondence?.archived) {
                  handleUnarchiveCall(stateId);
                } else if (stateType === 'email' && stateCorrespondence?.archived) {
                  handleUnarchiveEmail(stateId);
                } else if (stateType === 'call' && !stateCorrespondence?.archived) {
                  handleArchiveCall(stateId);
                } else if (stateType === 'email' && !stateCorrespondence?.archived) {
                  handleArchiveEmail(stateId);
                }
                onArchive(stateId);
              }}>
                {stateCorrespondence?.archived ? <ViewOffIcon mr={2} /> : <ViewIcon mr={2} />}
                {stateCorrespondence?.archived ? 'Unarchive' : 'Archive'}
              </Button>
            )}
          </HStack>
          <HStack gap={1}>
            <Button
              onClick={async () => {
                let data = {
                  [type === 'call' ? 'callId' : 'emailId']: id,
                  details: "",
                  sentiment: "POSITIVE"
                }
                let res = await axios.post(`${serverUrl}/api/feedback`, data, {
                  headers: {
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                  }
                });
                console.log(res);
                setFeedbackId(res.data.id);
                toast({
                  title: "Correspondence Feedback",
                  description: (
                    <>
                      Feedback on the correspondence submitted.{' '}
                      <Button variant="link" onClick={() => setShowFeedbackModal(true)} cursor={'pointer'}>
                        <Text color={'white'}>Click here to submit more details</Text>
                      </Button>
                    </>
                  ),
                  status: "info",
                  duration: 5000,
                  isClosable: true,
                })
              }}
            >
              <Icon as={IoMdHappy} color={'green'} />
            </Button>
            <Button
              onClick={async () => {
                let data = {
                  [type === 'call' ? 'callId' : 'emailId']: id,
                  details: "",
                  sentiment: "NEGATIVE"
                }
                let res = await axios.post(`${serverUrl}/api/feedback`, data, {
                  headers: {
                    'Authorization': `Bearer ${Cookies.get('jwtToken')}`
                  }
                });
                console.log(res);
                setFeedbackId(res.data.id);
                toast({
                  title: "Correspondence Feedback",
                  description: (
                    <>
                      Feedback on the correspondence submitted.{' '}
                      <Button variant="link" onClick={() => setShowFeedbackModal(true)} cursor={'pointer'}>
                        <Text color={'white'}>Click here to submit more details</Text>
                      </Button>
                    </>
                  ),
                  status: "info",
                  duration: 5000,
                  isClosable: true,
                })
              }}
            >
              <Icon as={IoMdSad} color={'red'} />
            </Button>
          </HStack>
        </HStack>
      </Box>
      <FeedbackModal isOpen={showFeedbackModal} onClose={() => setShowFeedbackModal(false)} type={type} id={feedbackId} onSubmit={handleFeedback} />
    </>
  );
};

export default BlueBox;