import React from 'react';
import { Box, VStack, Text, Link, HStack, Image, Spacer } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const Sidebar = () => {
  const { logout } = useAuth();

  const linkStyles = {
    display: 'block',
    padding: '10px 0',
    width: '100%',
    textAlign: 'left',
    transition: 'background-color 0.3s',
    borderRadius: 'md'
  };
  
  return (
    <Box as="nav" p={4} width="14%" minWidth="200px" height="100vh" pt={'60px'} bg="white" position="sticky" top="0">
      <Image src="/icons/Primary Logo.png" alt="datadasher icon" marginBottom={10} mt={4} />
      <VStack spacing={1} align="stretch">
        <Link as={NavLink}
              to="/"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <Image src="/icons/home.svg" alt="home icon" boxSize="20px" />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Home</Text>
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/clients"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <Image src="/icons/clients.svg" alt="clients icon" boxSize="20px" />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Clients</Text>
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/meetings"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <Image src="/icons/meetings.svg" alt="meetings icon" boxSize="20px" />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Meetings</Text>
          </HStack>
        </Link>


        <Link as={NavLink}
              to="/email-setup"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <Image src="/icons/email-setup.svg" alt="email setup icon" boxSize="20px" />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Email Setup</Text>
          </HStack>
        </Link>

        <Link as={NavLink}
              to="/integrations"
              sx={linkStyles}
              _hover={{ bg: 'gray.100' }}
              _activeLink={{ bg: 'blue.100' }}>
          <HStack px={4}>
            <Image src="/icons/integrations.svg" alt="integrations icon" boxSize="20px" />
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }} >Integrations</Text>
          </HStack>
        </Link>
        <Spacer />
        <Link
          as="button"
          onClick={logout}
          sx={linkStyles}
          _hover={{ bg: 'gray.100' }}
        >
          <HStack px={4}>
            <Text fontSize={{ base: "sm", md: "md", lg: "lg" }}>Logout</Text>
          </HStack>
        </Link>
      </VStack>
    </Box>
  );
};

export default Sidebar;