import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Home from './components/pages/Home/Home';
import Clients from './components/pages/Clients/Clients';
import Meetings from './components/pages/Meetings/Meetings';
import EmailSetup from './components/pages/EmailSetup/EmailSetup';
import Integrations from './components/pages/Integrations/Integrations';
import Correspondence from './components/pages/Clients/Correspondence';
import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Salesforce from './components/pages/Salesforce';
import RedtailConnect from './components/pages/RedtailConnect/RedtailConnect';
import { Box, Flex } from '@chakra-ui/react';
import './App.css';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import { useRef } from 'react';


function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const isAuthRoute = location.pathname === '/login' || location.pathname === '/signup';
  const scrollBoxRef = useRef(null);
  

  return (
    <Flex className="app" height="100vh" overflow="hidden">
      {!isAuthRoute && <Sidebar />}
      <Box ref={scrollBoxRef} flex="1" width={'100%'} bg={'#F5F5F5'} pt={!isAuthRoute ? 8 : 0} pl={!isAuthRoute ? 8 : 0} pr={!isAuthRoute ? 8 : 0} overflowY="auto">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<PrivateRoute><Home scrollRef={scrollBoxRef} /></PrivateRoute>} />
          <Route path="/clients" element={<PrivateRoute><Clients /></PrivateRoute>} />
          <Route path="/meetings" element={<PrivateRoute><Meetings /></PrivateRoute>} />
          <Route path="/email-setup" element={<PrivateRoute><EmailSetup /></PrivateRoute>} />
          <Route path="/integrations" element={<PrivateRoute><Integrations /></PrivateRoute>} />
          <Route path="/correspondence" element={<PrivateRoute><Correspondence /></PrivateRoute>} />
          <Route path="/salesforce" element={<PrivateRoute><Salesforce /></PrivateRoute>} />
          <Route path="/transcripts/:id" element={<PrivateRoute><Correspondence /></PrivateRoute>} />
          <Route path="/redtail/connect" element={<PrivateRoute><RedtailConnect /></PrivateRoute>} />
        </Routes>
      </Box>
    </Flex>
  );
}

export default App;